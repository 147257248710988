import { useQuery } from 'react-query';
import api from '@/services/lighthouse';
import { usePlatform } from '@/context/PlatformContext';

export default function useFetchDataConnectors({
  businessUnitId,
  parentDataConnectorId,
  cloudAccountId,
  ccaEnabled,
  kind,
  page,
  status,
  pageSize = 10,
  search = '',
  enabled = true,
} = {}) {
  const { business } = usePlatform();
  return useQuery(
    [
      'data-connectors',
      kind,
      search,
      page,
      pageSize,
      status,
      businessUnitId,
      parentDataConnectorId,
      business.id,
      cloudAccountId,
      ccaEnabled,
    ],
    () =>
      api
        .get(`/data_connectors`, {
          params: {
            kind,
            page,
            search,
            page_size: pageSize,
            enabled: status,
            business_unit_id: businessUnitId,
            parent_data_connector_id: parentDataConnectorId,
            cloud_account_id: cloudAccountId,
            cca_enabled: ccaEnabled,
          },
        })
        .then((res) => res),
    {
      enabled,
      retry: 0,
    }
  );
}
