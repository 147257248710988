import React, { useState, useMemo, useEffect } from 'react';
import { Modal, Form, Button, Select, Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { usePlatform } from '@/context/PlatformContext';
import useFieldsList from '@/hooks/useFieldsList';
import { NumberOutlined } from '@ant-design/icons';
import LoadingSpinner from '@/components/LoadingSpinner';
import withModal from '@/components/withModal';
import useMutationUpdateBudgetSettings from '@/mutations/useMutationUpdateBudgetSettings';
import useMutationDeleteBudgetSettings from '@/mutations/useMutationDeleteBudgetSettings';
import useFetchBudgetSettings from '@/queries/useFetchBudgetSettings';
import useFetchDataConnectors from '@/queries/useFetchDataConnectors';
import useFetchContexts from '@/queries/useFetchContexts';
import { getProviders } from '@/common/cloudProviders';

function BudgetSettingsModal({ onClose }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { billing } = usePlatform();
  const [settings, setSettings] = useState([]);

  const selectedBilling = Form.useWatch('selected_billing', form);

  const { isLoading, fieldsList } = useFieldsList({
    dataTypes: ['number'],
    dataConnectorId: selectedBilling,
    enabled: !!selectedBilling,
  });

  const connectorsKind = useMemo(
    () =>
      getProviders()
        .map(({ billing }) => billing)
        .join(','),
    []
  );

  const fetchContexts = useFetchContexts();
  const fetchDataConnectors = useFetchDataConnectors({
    pageSize: 9999,
    kind: connectorsKind,
    status: true,
  });
  const fetchSettings = useFetchBudgetSettings({});
  const mutateSettings = useMutationUpdateBudgetSettings();
  const deleteSettings = useMutationDeleteBudgetSettings();

  useEffect(() => {
    if (
      fetchSettings.isSuccess &&
      settings.length === 0 &&
      fetchSettings.data.length > 0
    ) {
      const selectedSetting = fetchSettings.data.find(
        (setting) => setting.provider === filteredContext.provider
      );

      form.setFieldsValue({
        selected_field: selectedSetting?.data?.name,
      });
    }
  }, [fetchSettings, settings]);

  const billingConnectors = useMemo(() => {
    return fetchDataConnectors.isSuccess
      ? fetchDataConnectors.data.data_connectors
      : [];
  }, [fetchDataConnectors]);

  const handleClear = (provider) => () => {
    setSettings((prev) =>
      prev.map((setting) =>
        setting.provider === provider
          ? { ...setting, data: null, status: 'deleted' }
          : setting
      )
    );
  };

  const handleSelect = (provider) => (name) => {
    setSettings((prev) => {
      const field = fieldsList.find((item) => item.name === name);
      if (!field) {
        throw new Error(`Field not found: ${name}`);
      }

      const key =
        field.kind === 'custom'
          ? field.calculated_field?.default_version?.key
          : `"${field.name}"`;

      const newSetting = {
        data: {
          fieldId: field.id,
          name: field.name,
          key,
          custom: field.kind === 'custom',
        },
        provider,
        status: 'created',
      };

      const existingIndex = prev.findIndex(
        (setting) => setting.provider === provider
      );

      if (existingIndex === -1) {
        return [...prev, newSetting];
      } else {
        return prev.map((setting, index) =>
          index === existingIndex ? newSetting : setting
        );
      }
    });
  };

  const filteredContext = useMemo(() => {
    const selectedBillingData = billingConnectors.find(
      (connector) => connector.id === selectedBilling
    );

    const billingContextsProviders = {
      aws: 'aws-billing',
      gcp: 'gcp-billing',
      azure: 'azure-billing',
      oci: 'oci-billing',
      datadog: 'datadog',
      openai: 'openai',
      elastic: 'elastic-cloud',
      mongodb_atlas: 'mongodb-atlas',
      kubecost: 'kubecost',
      onpremise: 'onpremise-billing',
      focus: 'focus',
      ibm: 'ibm-billing',
    };

    const filteredContext = fetchContexts.isSuccess
      ? fetchContexts.data.contexts.find(
          (context) =>
            billingContextsProviders[context?.provider] ===
            selectedBillingData?.kind
        )
      : {};

    return filteredContext;
  }, [selectedBilling, fetchContexts, billingConnectors]);

  const handleSave = async () => {
    const updatedSettings = [];

    for await (const setting of settings) {
      if (setting.status === 'created' || setting.status === 'edited') {
        const updatedSetting = await mutateSettings.mutateAsync({
          provider: setting.provider,
          data: setting.data,
        });
        updatedSettings.push({ ...updatedSetting, status: 'saved' });
      } else if (setting.status === 'deleted' && setting.id) {
        await deleteSettings.mutateAsync(setting.id);
      } else {
        updatedSettings.push(setting);
      }
    }

    setSettings(updatedSettings);

    onClose();
  };

  return (
    <Modal
      open
      onCancel={onClose}
      title={t('common.settings')}
      footer={[
        <Button key="close" onClick={onClose}>
          {t('common.close')}
        </Button>,
        <Button
          form="budget-settings"
          key="submit"
          htmlType="submit"
          type="primary"
        >
          {t('common.save')}
        </Button>,
      ]}
    >
      {fetchSettings.isLoading || fetchSettings.isFetching ? (
        <LoadingSpinner height={200} />
      ) : (
        <Form
          form={form}
          id="budget-settings"
          autoComplete="off"
          layout="vertical"
          onFinish={handleSave}
        >
          <Form.Item
            label={'Billing'}
            tooltip={t('common.chargeback.billing.connector.tooltip')}
            name="selected_billing"
            initialValue={billing?.id}
            rules={[
              {
                required: true,
                message: t('common.chargeback.billing.connector.required'),
              },
            ]}
          >
            <Select
              showSearch
              loading={isLoading}
              onChange={(value) =>
                form.setFieldsValue({ selected_billing: value })
              }
            >
              {billingConnectors.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {selectedBilling && (
            <Form.Item
              key={`context-${filteredContext.id}`}
              label={t('common.chargeback.field', {
                context: filteredContext.name,
              })}
              name="selected_field"
            >
              <Select
                showSearch
                loading={isLoading}
                allowClear
                onChange={(value) => {
                  if (value) {
                    handleSelect(filteredContext.provider)(value);
                  } else {
                    handleClear(filteredContext.provider)();
                  }
                }}
              >
                {fieldsList.map(({ name }) => (
                  <Select.Option key={name} value={name}>
                    <Space>
                      <NumberOutlined className="icon" />
                      <Typography.Text type="primary">{name}</Typography.Text>
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      )}
    </Modal>
  );
}

export default withModal({
  key: 'BUDGET_SETTINGS',
  component: BudgetSettingsModal,
});
