import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationPT_BR from '@/assets/locales/pt-br/translation.json';
import translationEN_US from '@/assets/locales/en-us/translation.json';
import translationES_ES from '@/assets/locales/es-es/translation.json';

import lighthouseTranslationPT_BR from '@/assets/locales/pt-br/lighthouse';
import lighthouseTranslationEN_US from '@/assets/locales/en-us/lighthouse';
import lighthouseTranslationES_ES from '@/assets/locales/es-es/lighthouse';

import CCATranslationPT_BR from '@/assets/locales/pt-br/cca/translation.json';
import CCATranslationEN_US from '@/assets/locales/en-us/cca/translation.json';
import CCATranslationES_ES from '@/assets/locales/es-es/cca/translation.json';

import AutofixTranslationPT_BR from '@/assets/locales/pt-br/autofix/translation.json';
import AutofixTranslationEN_US from '@/assets/locales/en-us/autofix/translation.json';
import AutofixTranslationES_ES from '@/assets/locales/es-es/autofix/translation.json';

import settingsTranslationPT_BR from '@/assets/locales/pt-br/settings';
import settingsTranslationEN_US from '@/assets/locales/en-us/settings';
import settingsTranslationES_ES from '@/assets/locales/es-es/settings';

import spotTranslationPT_BR from '@/assets/locales/pt-br/spot/translation.json';
import spotTranslationEN_US from '@/assets/locales/en-us/spot/translation.json';
import spotTranslationES_ES from '@/assets/locales/es-es/spot/translation.json';

import spManagerTranslationPT_BR from '@/assets/locales/pt-br/spManager/translation.json';
import spManagerTranslationEN_US from '@/assets/locales/en-us/spManager/translation.json';
import spManagerTranslationES_ES from '@/assets/locales/es-es/spManager/translation.json';

import authTranslationPT_BR from '@/assets/locales/pt-br/auth/translation.json';
import authTranslationEN_US from '@/assets/locales/en-us/auth/translation.json';
import authTranslationES_ES from '@/assets/locales/es-es/auth/translation.json';

import ticketsTranslationPT_BR from '@/assets/locales/pt-br/tickets/translation.json';
import ticketsTranslationEN_US from '@/assets/locales/en-us/tickets/translation.json';
import ticketsTranslationES_ES from '@/assets/locales/es-es/tickets/translation.json';

const fallbackLng = ['pt-BR'];
const availableLanguages = ['pt-BR', 'en-US', 'es-ES'];

const resources = {
  'pt-BR': {
    translation: translationPT_BR,
    lighthouse: lighthouseTranslationPT_BR,
    cca: CCATranslationPT_BR,
    autofix: AutofixTranslationPT_BR,
    settings: settingsTranslationPT_BR,
    auth: authTranslationPT_BR,
    spot: spotTranslationPT_BR,
    spManager: spManagerTranslationPT_BR,
    tickets: ticketsTranslationPT_BR,
  },
  'en-US': {
    translation: translationEN_US,
    lighthouse: lighthouseTranslationEN_US,
    cca: CCATranslationEN_US,
    autofix: AutofixTranslationEN_US,
    settings: settingsTranslationEN_US,
    auth: authTranslationEN_US,
    spot: spotTranslationEN_US,
    spManager: spManagerTranslationEN_US,
    tickets: ticketsTranslationEN_US,
  },
  'es-ES': {
    translation: translationES_ES,
    lighthouse: lighthouseTranslationES_ES,
    cca: CCATranslationES_ES,
    autofix: AutofixTranslationES_ES,
    settings: settingsTranslationES_ES,
    auth: authTranslationES_ES,
    spot: spotTranslationES_ES,
    spManager: spManagerTranslationES_ES,
    tickets: ticketsTranslationES_ES,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    debug: process.env.NODE_ENV === 'development',
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
