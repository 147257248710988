import CreateFeatureFlags from './CreateFeatureFlags';
import DeleteFeatureFlag from './DeleteFeatureFlag';
import BudgetSettingsModal from './BudgetSettingsModal';
import ColorsPalettesModal from './ColorsPalettesModal';

function Modals() {
  return (
    <>
      <CreateFeatureFlags />
      <DeleteFeatureFlag />
      <BudgetSettingsModal />
      <ColorsPalettesModal />
    </>
  );
}

export default Modals;
