import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';
import { apiWithoutBaseUrl } from '@/services/lighthouse';
import { useTranslation } from 'react-i18next';
import { usePlatform } from '@/context/PlatformContext';

function useMutationUpdateColorsPalettes() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { org, business } = usePlatform();

  return useMutation(
    ({ default_palette_id, colors_palettes }) =>
      apiWithoutBaseUrl.patch(
        `/orgs/${org.id}/businesses/${business.id}/palettes`,
        {
          default_palette_id,
          colors_palettes,
        }
      ),
    {
      onSuccess() {
        queryClient.invalidateQueries(['colors-palettes', org.id, business.id]);
      },
      onError() {
        message.error({
          content: t('settings:business.editing.error'),
          key: 'business-update',
          duration: 4,
        });
      },
    }
  );
}

export default useMutationUpdateColorsPalettes;
