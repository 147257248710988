import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./pages/home'));
const New = lazy(() => import('./pages/new'));
const View = lazy(() => import('./pages/view'));
const Created = lazy(() => import('./pages/created'));

function Routes() {
  return (
    <Switch>
      <PrivateRoute
        renderSiderMenu={false}
        exact
        path={'/tickets'}
        title="Tickets"
        component={Home}
      />
      <PrivateRoute
        exact
        renderSiderMenu={false}
        path={'/tickets/new'}
        title="Novo ticket"
        component={New}
      />
      <PrivateRoute
        exact
        renderSiderMenu={false}
        path={'/tickets/:id/created'}
        title="Ticket created"
        component={Created}
      />
      <PrivateRoute
        exact
        renderSiderMenu={false}
        path={'/tickets/:id'}
        title="Ticket"
        component={View}
      />
    </Switch>
  );
}

export default Routes;
