import { useState } from 'react';
import { Tooltip, Popover, Space } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Content from './Content';
import pxToRem from '@/common/pxToRem';
import { CloseOutlined } from '@ant-design/icons';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  sample: {
    borderRadius: '50%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  colorBox: {
    alignContent: 'center',
    padding: 4,
    backgroundColor: '#f0f2f5',
    borderRadius: 4,
  },
  close: {
    fontSize: pxToRem(12),
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function useScript({ color, onChange, onDelete }) {
  const [open, setOpen] = useState(false);

  function handleSave(newColor) {
    onChange(newColor);
    setOpen(false);
  }

  function handleDelete(e) {
    e.stopPropagation();
    onDelete(color);
  }

  return {
    open,
    setOpen,
    handleSave,
    handleDelete,
  };
}

function ColorPicker({
  size,
  color,
  onChange,
  showPopover = true,
  showDelete,
  onDelete,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { open, setOpen, handleSave, handleDelete } = useScript({
    color,
    onChange,
    onDelete,
  });

  if (!showPopover) {
    if (!showDelete) {
      return (
        <div
          style={{
            backgroundColor: color,
            width: size,
            height: size,
            borderRadius: '50%',
          }}
        />
      );
    }

    return (
      <Space size={4} className={clsx(showDelete && classes.colorBox)}>
        <div
          style={{
            backgroundColor: color,
            width: size,
            height: size,
            borderRadius: '50%',
          }}
        />
        <CloseOutlined className={classes.close} onClick={handleDelete} />
      </Space>
    );
  }

  if (!showDelete) {
    return (
      <Popover
        open={open}
        content={
          <Content
            color={color}
            onCancel={() => setOpen(false)}
            onSave={handleSave}
          />
        }
        trigger="click"
        placement="bottomLeft"
        onOpenChange={setOpen}
        arrowPointAtCenter
      >
        <Tooltip
          title={t('colorpicker.edit.color')}
          placement="topLeft"
          arrowPointAtCenter
        >
          <div
            className={classes.sample}
            style={{ backgroundColor: color, width: size, height: size }}
          />
        </Tooltip>
      </Popover>
    );
  }

  return (
    <Space size={4} className={clsx(showDelete && classes.colorBox)}>
      <Popover
        open={open}
        content={
          <Content
            color={color}
            onSave={handleSave}
            onCancel={() => setOpen(false)}
          />
        }
        trigger="click"
        placement="bottomLeft"
        onOpenChange={setOpen}
        arrowPointAtCenter
      >
        <Tooltip
          title={t('colorpicker.edit.color')}
          placement="topLeft"
          arrowPointAtCenter
        >
          <div
            className={classes.sample}
            style={{ backgroundColor: color, width: size, height: size }}
          />
        </Tooltip>
      </Popover>

      <CloseOutlined
        className={classes.close}
        onClick={(e) => handleDelete(e)}
      />
    </Space>
  );
}

export default ColorPicker;
