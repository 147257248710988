import datasets from './datasets.json';
import data_centers from './data_centers.json';
import enrichment_rules from './enrichment_rules.json';
import lia from './lia.json';
import buget_receivers from './budget_receivers.json';
import translation from './translation.json';
import advanced_chargeback from './advanced_chargeback.json';
import checking_account from './checking_account.json';
import checking_account_relations from './checking_account_relations.json';
import export_import_workspaces from './export_import_workspaces.json';

const translations = {
  ...datasets,
  ...enrichment_rules,
  ...lia,
  ...data_centers,
  ...advanced_chargeback,
  ...buget_receivers,
  ...checking_account,
  ...translation,
  ...checking_account_relations,
  ...export_import_workspaces,
};

export default translations;
