import { SketchPicker } from 'react-color';
import { Button, Divider, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import VerticalSpace from '@/components/VerticalSpace';
import { createUseStyles } from 'react-jss';
import { useState } from 'react';
import { defaultColors, presetColors } from './index';

const useStyles = createUseStyles(() => ({
  picker: {
    padding: '0 !important',
    boxShadow: 'none !important',
  },
  btn: {
    borderRadius: 4,
  },
}));

function Content({ color, onCancel, onSave }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [c, setColor] = useState(color);

  return (
    <VerticalSpace>
      <SketchPicker
        className={classes.picker}
        color={c}
        presetColors={[...defaultColors, ...presetColors]}
        width={250}
        onChange={({ hex }) => setColor(hex)}
      />

      <Divider style={{ marginBlock: 0 }} />

      <Space size={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button className={classes.btn} size="small" onClick={() => onCancel()}>
          {t('common.cancel')}
        </Button>
        <Button
          className={classes.btn}
          size="small"
          type="primary"
          onClick={() => onSave(c)}
        >
          {t('common.save')}
        </Button>
      </Space>
    </VerticalSpace>
  );
}

export default Content;
