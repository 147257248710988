import { useCallback, useMemo } from 'react';
import { Button, Col, Dropdown, Row, Tag } from 'antd';
import { PaletteColorsPicker } from '@/components/PaletteColorPicker';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import VerticalSpace from '@/components/VerticalSpace';
import LoadingSpinner from '@/components/LoadingSpinner';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles(() => ({
  box: {
    padding: 2,
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
      background: '#f5f5f5',
    },
  },
  active: {
    background: '#e6f7ff',
    borderColor: '#1890ff',
  },
}));

function ColorsPaletteCard({
  id,
  colors,
  isSelected,
  isDefault,
  onChange,
  onClick,
  onDelete,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const menuItems = useMemo(
    () => [
      {
        key: 'SET_AS_DEFAULT',
        label: t('common.set.as.default'),
        icon: <CheckOutlined />,
      },
      {
        key: 'DELETE',
        label: t('common.delete'),
        icon: <DeleteOutlined />,
        danger: true,
        disabled: isSelected,
      },
    ],
    [t, isSelected]
  );

  function handleMenuClick({ key, domEvent }) {
    domEvent.stopPropagation();
    if (key === 'SET_AS_DEFAULT') {
      onChange(id);
      return;
    }
    onDelete(id);
  }

  return (
    <Row
      justify="start"
      align="middle"
      wrap={false}
      gutter={8}
      className={clsx(
        classes.box,
        !isSelected && classes.hover,
        isSelected && classes.active
      )}
      onClick={onClick}
    >
      <Col flex="auto">
        <PaletteColorsPicker
          colors={colors}
          onChange={onChange}
          showAddColor={false}
          bordered={false}
        />
      </Col>

      {isDefault && (
        <Col flex="80px">
          <Tag color="#1890ff" style={{ borderRadius: 4 }}>
            {t('common.default')}
          </Tag>
        </Col>
      )}

      <Col flex="40px">
        {!isDefault && (
          <Dropdown
            menu={{
              items: menuItems,
              onClick: handleMenuClick,
            }}
            trigger={['click']}
          >
            <Button
              type="text"
              shape="circle"
              icon={
                <MoreOutlined
                  style={{ fontSize: '1rem', fontWeight: 'bold' }}
                />
              }
              onClick={(e) => e.stopPropagation()}
            />
          </Dropdown>
        )}
      </Col>
    </Row>
  );
}

function ColorsPalettesList({
  loading = false,
  dataSource = [],
  selected,
  defaultPaletteId,
  onClick,
  onChange,
  onDelete,
}) {
  const isSelected = useCallback((id) => selected === id, [selected]);
  const isDefault = useCallback(
    (id) => defaultPaletteId === id,
    [defaultPaletteId]
  );

  if (loading) {
    return <LoadingSpinner height={300} />;
  }

  return (
    <VerticalSpace>
      {dataSource.map((palette) => (
        <ColorsPaletteCard
          {...palette}
          key={palette.id}
          isSelected={isSelected(palette.id)}
          isDefault={isDefault(palette.id)}
          onClick={() => onClick(palette.id)}
          onChange={onChange}
          onDelete={onDelete}
        />
      ))}
    </VerticalSpace>
  );
}

export default ColorsPalettesList;
