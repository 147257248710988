import { usePlatform } from '@/context/PlatformContext';

function useFeatureFlag({ alias }) {
  const { featureFlags } = usePlatform();

  const is_flaged = featureFlags.has(alias);

  return !is_flaged;
}

export default useFeatureFlag;
