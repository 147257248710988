import { Button, Col, Row, Space, Tooltip } from 'antd';
import ColorPicker from './ColorPicker';
import { createUseStyles } from 'react-jss';
import { PlusOutlined } from '@ant-design/icons';
import pxToRem from '@/common/pxToRem';
import clsx from 'clsx';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';

export const defaultColors = [
  '#008FFB',
  '#00E396',
  '#FEB019',
  '#FF4560',
  '#775DD0',
  '#3F51B5',
  '#4CAF50',
  '#4ecdc4',
  '#c7f464',
  '#F86624',
  '#A300D6',
  '#EA3546',
  '#5A2A27',
];

export const presetColors = [
  '#000000',
  '#ff0000',
  '#00ff00',
  '#ffff00',
  '#ff00ff',
  '#00ffff',
  '#808080',
];

const useStyles = createUseStyles(() => ({
  box: {
    padding: 4,
    width: '100%',
  },
  border: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
  },
  addBtn: {
    height: 30,
    width: 30,
    borderRadius: 4,
  },
  colorsBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

function useScript({ colors, onChange }) {
  function handleColorChange(newColor, index) {
    const copy = [...colors];
    copy[index] = newColor;
    onChange([...copy]);
  }

  function handleAddColor(e) {
    e.stopPropagation();
    const allColors = [...defaultColors, ...presetColors];
    const newColor = allColors[colors.length % allColors.length];
    onChange([...colors, newColor]);
  }

  function handleDeleteColor(index) {
    onChange(colors.toSpliced(index, 1));
  }

  return {
    handleAddColor,
    handleColorChange,
    handleDeleteColor,
  };
}

export function PaletteColorsPicker({
  colors = [],
  size = 22,
  onChange,
  showAddColor = true,
  bordered = true,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { handleAddColor, handleColorChange, handleDeleteColor } = useScript({
    colors,
    onChange,
  });

  return (
    <Row
      className={clsx(classes.box, bordered && classes.border)}
      justify="start"
      align="middle"
      style={{ marginInline: 0 }}
      wrap={false}
    >
      <Col flex="auto" key={uniqid()}>
        <Space size={4} className={classes.colorsBox}>
          {colors.map((color, index) => (
            <ColorPicker
              size={size}
              color={color}
              showPopover={showAddColor}
              showDelete={colors.length > 1 && showAddColor}
              onChange={(value) => handleColorChange(value, index)}
              onDelete={() => handleDeleteColor(index)}
            />
          ))}
        </Space>
      </Col>

      {showAddColor && (
        <Col>
          <Tooltip title={t('common.add')}>
            <Button
              className={classes.addBtn}
              size="small"
              icon={<PlusOutlined style={{ fontSize: pxToRem(16) }} />}
              onClick={(e) => handleAddColor(e)}
            />
          </Tooltip>
        </Col>
      )}
    </Row>
  );
}

export default PaletteColorsPicker;
