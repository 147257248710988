import { lazy } from 'react';
import { PublicRoute } from './components';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import CCARoutes from './modules/cca/Routes';
import AutofixRoutes from './modules/autofix/Routes';
import SettingsRoutes from './modules/settings/Routes';
import LighthouseRoutes from './modules/lighthouse/Routes';
import SpaceRoutes from './modules/space/Routes';
import SpotRoutes from './modules/spot/Routes';
import SetupRoutes from './modules/setup/Routes';
import SpManagerRoutes from './modules/spManager/Routes';
import ErrorsRoutes from './modules/errors/Routes';
import TicketsRoutes from './modules/tickets/Routes';

const publicRoutes = [
  {
    path: '/auth/login',
    translate: 'Login',
    component: lazy(() => import('./modules/authLogin')),
    configured: false,
  },
];

function Routes() {
  return (
    <Router>
      <Switch>
        <Route
          path="/login"
          component={() => {
            window.location = process.env.REACT_APP_KEYCLOAK_LOGIN_URL;
            return null;
          }}
        />

        {publicRoutes.map((route) => (
          <PublicRoute key={route.path} {...route} />
        ))}

        <Route path="/settings*">
          <SettingsRoutes />
        </Route>

        <Route path="/cca*">
          <CCARoutes />
        </Route>

        <Route path={`/autofix*`}>
          <AutofixRoutes />
        </Route>

        <Route path={['/lighthouse*', '/workspaces']}>
          <LighthouseRoutes />
        </Route>

        <Route path={`/space*`}>
          <SpaceRoutes />
        </Route>

        <Route path={`/spot*`}>
          <SpotRoutes />
        </Route>

        <Route path={`/sp-manager*`}>
          <SpManagerRoutes />
        </Route>

        <Route path={`/setup/*`}>
          <SetupRoutes />
        </Route>

        <Route path={`/tickets*`}>
          <TicketsRoutes />
        </Route>

        <Route path={`/errors*`}>
          <ErrorsRoutes />
        </Route>

        <Redirect from="/" to="/lighthouse/workspaces" />
      </Switch>
    </Router>
  );
}

export default Routes;
