import {
  AlertOutlined,
  AppstoreOutlined,
  BookOutlined,
  ClusterOutlined,
  DatabaseOutlined,
  DollarCircleOutlined,
  LineChartOutlined,
  FileOutlined,
  CalculatorOutlined,
  ExceptionOutlined,
  RiseOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import getOnlyVisibleMenuItems from '@/common/onlyVisibleMenuItems';
import { pierRoles } from '@/context/AuthContext';
import { getAllProvidersKeys } from '@/common/cloudProviders';

const items = ({ t, adminRole, provider, featureFlags, business }) => {
  const visibilityAndStorageProviders = [
    'datadog',
    'elastic',
    'kubecost',
    'mongodb',
    'openai',
  ];

  const allItems = [
    {
      key: 'lighthouse/workspaces',
      label: t('menu.sidebar.workspaces'),
      icon: <AppstoreOutlined />,
    },
    {
      key: 'lighthouse/lia',
      label: t('lighthouse:lia.title'),
      icon: <LineChartOutlined />,
      hide: featureFlags.has('lia') ? true : undefined,
      providers: getFilteredProviders(
        'focus',
        'onpremise',
        ...visibilityAndStorageProviders
      ),
      children: [
        {
          key: 'lighthouse/lia/reports',
          label: t('common.reports'),
        },
        {
          key: 'lighthouse/lia/personas',
          label: 'Personas',
          permissions: pierRoles,
          global: true,
        },
        {
          key: 'lighthouse/lia/templates',
          label: 'Templates',
          permissions: pierRoles,
          global: true,
        },
        {
          key: 'lighthouse/lia/actions',
          label: t('common.actions'),
          permissions: pierRoles,
          global: true,
        },
      ],
    },
    {
      key: 'lighthouse/reports',
      label: t('common.reports'),
      icon: <ContainerOutlined />,
    },
    getCalculatedFieldsItem({
      t,
      adminRole,
      permissions: pierRoles,
      business,
    }),
    {
      key: 'lighthouse/data-centers',
      label: t('menu.sidebar.on.premise.costs'),
      icon: <ClusterOutlined />,
      providers: ['onpremise'],
    },
    {
      key: 'lighthouse/drafts',
      label: t('menu.sidebar.drafts'),
      icon: <FileOutlined />,
    },
    {
      key: 'lighthouse/timeline',
      label: t('menu.sidebar.timelines'),
      icon: <LineChartOutlined />,
      providers: getFilteredProviders('focus'),
    },
    {
      key: 'lighthouse/budget',
      label: t('menu.sidebar.budgets'),
      icon: <DollarCircleOutlined />,
      providers: getFilteredProviders('focus'),
      children: [
        {
          key: 'lighthouse/budget/organizational-map',
          label: t('menu.sidebar.maps'),
        },
        {
          key: 'lighthouse/budget/receivers',
          label: t('menu.sidebar.receivers'),
          global: 'true',
        },
        {
          key: 'lighthouse/budget/provisioners',
          label: t('lighthouse:provisioners'),
          global: 'true',
        },
        {
          key: 'lighthouse/budget/advanced-chargeback',
          label: t('menu.sidebar.advanced.chargeback'),
        },
      ],
    },
    {
      key: 'lighthouse/forecast',
      label: t('router.forecast.title'),
      icon: <RiseOutlined />,
      providers: getFilteredProviders(
        'focus',
        ...visibilityAndStorageProviders
      ),
      children: [
        {
          key: 'lighthouse/forecast/infrastructure',
          label: t('lighthouse:forecast.infrastructure'),
        },
        {
          key: 'lighthouse/forecast/business',
          label: t('lighthouse:forecast.business'),
        },
      ],
    },
    {
      key: 'lighthouse/alarms',
      label: t('menu.sidebar.alarms'),
      icon: <AlertOutlined />,
      providers: getFilteredProviders('focus'),
    },
    {
      key: 'lighthouse/datasets',
      label: t('menu.sidebar.dataset'),
      icon: <DatabaseOutlined />,
      global: 'true',
      permissions: pierRoles,
    },
    {
      key: 'lighthouse/anomalies',
      label: t('menu.sidebar.anomalies'),
      icon: <ExceptionOutlined />,
      providers: ['aws', 'azure', 'gcp', 'oci'],
      children: [
        {
          key: 'lighthouse/anomalies/infrastructure',
          label: t('menu.sidebar.anomalies.infrastructure'),
        },
        {
          key: 'lighthouse/anomalies/business',
          label: t('menu.sidebar.anomalies.business'),
        },
      ],
    },
    {
      key: 'lighthouse/tag-dictionary',
      label: t('menu.sidebar.tag.dictionary'),
      icon: <BookOutlined />,
      providers: getFilteredProviders('focus'),
    },
  ];

  return getOnlyVisibleMenuItems({ items: allItems, adminRole, provider });
};

function getFilteredProviders(...excludedProviders) {
  return getAllProvidersKeys({ except: excludedProviders });
}

function getCalculatedFieldsItem({ t, adminRole, permissions, business }) {
  if (permissions.includes(adminRole) || business?.global) {
    return {
      key: 'lighthouse/calculated-fields',
      label: t('menu.sidebar.calculated.fields'),
      icon: <CalculatorOutlined />,
      children: [
        {
          key: 'lighthouse/calculated-fields/global',
          label: t('menu.sidebar.calculated.fields.pier'),
          permissions,
        },
        {
          key: 'lighthouse/calculated-fields/organization',
          label: t('menu.sidebar.calculated.fields.org'),
          hide: !business?.global,
        },
        {
          key: 'lighthouse/calculated-fields/custom',
          label: t('menu.sidebar.calculated.fields.custom'),
        },
      ],
    };
  }

  return {
    key: 'lighthouse/calculated-fields/custom',
    label: t('menu.sidebar.calculated.fields.custom'),
    icon: <CalculatorOutlined />,
  };
}

export default items;
