import { Alert, Button, Col, Modal, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import withModal from '@/components/withModal';
import { PaletteColorsPicker } from '@/components/PaletteColorPicker';
import VerticalSpace from '@/components/VerticalSpace';
import Scrollable from '@/components/Scrollable';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import ColorsPalettesList from './ColorsPalettesList';
import useColorsPalettes from '@/hooks/useColorsPalettes';

const MODAL_WIDTH = 600;

const { Title } = Typography;

function useScript({ onClose, onSave }) {
  const {
    isLoading,
    colorsPalettes,
    defaultPaletteId,
    defaultColor,
    addPalette,
    deletePalette,
    setDefaultPaletteId,
  } = useColorsPalettes();

  const [adding, setAdding] = useState(false);
  const [colors, setColors] = useState([defaultColor]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (!selected && defaultPaletteId) {
      setSelected(defaultPaletteId);
    }
  }, [selected, defaultPaletteId]);

  const scrollabeHeight = useMemo(
    () => (!adding && colorsPalettes.length > 10 ? 500 : '100%'),
    [adding]
  );

  function handleSave() {
    if (!adding) {
      const { colors } = colorsPalettes.find((p) => p.id === selected);
      onSave(colors);
      onClose();
      return;
    }

    const { id } = addPalette(colors);
    setSelected(id);
    resetColorsForm();
  }

  function handleCancel() {
    if (!adding) {
      onClose();
      return;
    }
    resetColorsForm();
  }

  function resetColorsForm() {
    setAdding(false);
    setColors([defaultColor]);
  }

  return {
    colorsPalettes,
    defaultPaletteId,
    handleCancel,
    handleSave,
    adding,
    scrollabeHeight,
    setAdding,
    colors,
    setColors,
    selected,
    setSelected,
    setDefaultPaletteId,
    deletePalette,
    isLoading,
  };
}

function ColorsPalettesModal({ onClose, onSave }) {
  const { t } = useTranslation();

  const {
    colorsPalettes,
    defaultPaletteId,
    handleCancel,
    handleSave,
    adding,
    scrollabeHeight,
    setAdding,
    colors,
    setColors,
    selected,
    setSelected,
    setDefaultPaletteId,
    deletePalette,
    isLoading,
  } = useScript({ onClose, onSave });

  return (
    <Modal
      title={t('view.form.colors.palettes')}
      open={true}
      onCancel={() => handleCancel()}
      onOk={() => handleSave()}
      okText={adding ? t('common.save') : t('common.select')}
      width={MODAL_WIDTH}
      bodyStyle={{ padding: 0 }}
      okButtonProps={{ disabled: isLoading }}
    >
      <Scrollable padding="16px 24px" height={scrollabeHeight}>
        <VerticalSpace>
          <Alert
            message={t('view.form.default.colors.palette.warning')}
            type="warning"
            showIcon
            closable
          />

          <VerticalSpace size={4}>
            <Row align="middle">
              <Col flex="auto">
                <Title level={5}>
                  {adding
                    ? t('view.form.colors.palettes.add')
                    : t('view.form.colors.palettes.select')}
                </Title>
              </Col>

              {!adding && (
                <Col>
                  <Button
                    type="text"
                    shape="round"
                    icon={<PlusOutlined />}
                    style={{ fontWeight: 600, color: '#1890ff' }}
                    onClick={() => setAdding(true)}
                  >
                    {t('common.add')}
                  </Button>
                </Col>
              )}
            </Row>

            {adding && (
              <PaletteColorsPicker colors={colors} onChange={setColors} />
            )}
          </VerticalSpace>

          {!adding && (
            <ColorsPalettesList
              dataSource={colorsPalettes}
              selected={selected}
              defaultPaletteId={defaultPaletteId}
              onClick={setSelected}
              onChange={setDefaultPaletteId}
              onDelete={deletePalette}
            />
          )}
        </VerticalSpace>
      </Scrollable>
    </Modal>
  );
}

export default withModal({
  key: 'COLORS_PALETTES_MODAL',
  component: ColorsPalettesModal,
});
