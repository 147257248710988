import {
  ExpandAltOutlined,
  FileTextOutlined,
  PoweroffOutlined,
  TagOutlined,
  CalendarOutlined,
  StopOutlined,
  RollbackOutlined,
  GroupOutlined,
  DeploymentUnitOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { toLower } from '@/common/strFuncs';
import resources from './startStop/home/resources';
import returnOnlyVisibleMenuItems from '@/common/onlyVisibleMenuItems';
import AwsImgServices from '@/components/AwsImgServices/Index.jsx';

const items = ({ t, adminRole, provider, featureFlags }) => {
  const allItems = [
    {
      key: 'autofix/tag-manager',
      label: t('autofix:tagmanager'),
      icon: <TagOutlined />,
      providers: ['aws'],
    },
    {
      key: 'autofix/auto-tags',
      label: 'Auto tags',
      icon: <TagsOutlined />,
      providers: ['azure', 'aws'],
    },
    {
      key: 'autofix/smart-stop',
      label: 'Smart Stop',
      icon: <StopOutlined />,
      providers: ['aws'],
      children: [
        {
          key: `autofix/smart-stop/ec2`,
          label: 'EC2',
          icon: (
            <AwsImgServices kind="ec2" size={16} style={{ marginLeft: -2 }} />
          ),
        },
        {
          key: `autofix/smart-stop/rds`,
          label: 'RDS',
          icon: (
            <AwsImgServices
              kind="rds"
              size={26}
              style={{ marginLeft: -6, marginRight: -3 }}
            />
          ),
        },
        {
          key: `autofix/smart-stop/asg`,
          label: 'Auto Scaling Group',
          icon: (
            <AwsImgServices kind="asg" size={25} style={{ marginLeft: -4 }} />
          ),
        },
        {
          key: `autofix/smart-stop/override`,
          label: 'Override',
          icon: <RollbackOutlined />,
        },
      ],
    },
    {
      key: 'autofix/start-stop',
      label: 'Start - Stop',
      icon: <PoweroffOutlined />,
      providers: ['aws', 'azure'],
      children: [
        ...Array.from(resources)
          .filter(([_, a]) => {
            return a.kind === 'startstop';
          })
          .map(([key, item]) => ({
            key: `autofix/start-stop/${toLower(key)}`,
            label: item.label,
            icon: item.icon,
            providers: item.providers,
          })),
        {
          key: 'autofix/start-stop/schedules',
          label: t('autofix:start.stop.override.schedules'),
          icon: <CalendarOutlined />,
          providers: ['aws'],
        },
      ],
    },
    {
      key: 'autofix/scheduled-scaling',
      label: 'Scheduled Scaling',
      icon: <ExpandAltOutlined />,
      providers: ['aws'],
      children: [
        ...Array.from(resources)
          .filter(([_, a]) => {
            return a.kind === 'resize';
          })
          .map(([key, item]) => ({
            key: `autofix/scheduled-scaling/${toLower(key)}`,
            label: item.label,
            icon: item.icon,
          })),
        {
          key: 'autofix/scheduled-scaling/schedules',
          label: t('autofix:start.stop.override.schedules'),
          icon: <CalendarOutlined />,
          providers: ['aws'],
        },
      ],
    },
    {
      key: 'autofix/resource-groups',
      label: t('autofix:start.stop.resources.groups'),
      icon: <GroupOutlined />,
      providers: ['aws'],
      children: [
        {
          key: 'autofix/resource-groups/groups',
          label: `${t('common.group')}s`,
          icon: <DeploymentUnitOutlined />,
          providers: ['aws'],
        },
        {
          key: 'autofix/resource-groups/schedules',
          label: t('autofix:start.stop.override.schedules'),
          icon: <CalendarOutlined />,
          providers: ['aws'],
        },
      ],
    },
    {
      key: 'autofix/logs',
      label: 'Logs',
      icon: <FileTextOutlined />,
      providers: ['azure', 'aws'],
    },
  ];

  return returnOnlyVisibleMenuItems({
    items: allItems,
    adminRole,
    provider,
  });
};

export default items;
