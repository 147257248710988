import { useMemo } from 'react';
import { defaultColors } from '@/components/PaletteColorPicker';
import useMutationUpdateColorsPalettes from '@/mutations/useMatationUpdateColorsPalettes';
import useFetchColorsPalettes from '@/queries/useFetchColorsPalettes';
import { usePlatform } from '@/context/PlatformContext';
import uniqid from 'uniqid';

function useColorsPalettes() {
  const { business } = usePlatform();

  const cpData = useFetchColorsPalettes();
  const updateMutaion = useMutationUpdateColorsPalettes();

  const colorsPalettes = useMemo(
    () => cpData.data?.colors_palettes || business.colors_palettes || [],
    [cpData.data?.colors_palettes]
  );
  const defaultPaletteId = useMemo(
    () => cpData.data?.default_palette_id || business.default_palette_id,
    [cpData.data?.default_palette_id]
  );
  const defaultPallete = useMemo(
    () => colorsPalettes.find((p) => p.id === defaultPaletteId),
    [defaultPaletteId]
  );

  function addPalette(colors) {
    const newPalette = { id: uniqid(), colors };
    updateMutaion.mutate({ colors_palettes: [...colorsPalettes, newPalette] });
    return newPalette;
  }

  function deletePalette(id) {
    const filtered = colorsPalettes.filter((p) => p.id !== id);
    updateMutaion.mutate({ colors_palettes: filtered });
  }

  function setDefaultPaletteId(id) {
    updateMutaion.mutate({ default_palette_id: id });
  }

  return {
    isLoading: cpData.isLoading || cpData.isFetching,
    colorsPalettes,
    defaultPaletteId,
    setDefaultPaletteId,
    defaultColors: defaultPallete?.colors || defaultColors,
    defaultColor: defaultColors[0],
    addPalette,
    deletePalette,
  };
}

export default useColorsPalettes;
