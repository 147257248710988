import { useQuery } from 'react-query';
import { apiWithoutBaseUrl } from '@/services/lighthouse';
import { usePlatform } from '@/context/PlatformContext';

function useFetchColorsPalettes({ enabled = true } = {}) {
  const { org, business } = usePlatform();

  return useQuery(
    ['colors-palettes', org.id, business.id],
    () =>
      apiWithoutBaseUrl
        .get(`/orgs/${org.id}/businesses/${business.id}/palettes`)
        .then((res) => res.data),
    {
      enabled,
    }
  );
}

export default useFetchColorsPalettes;
