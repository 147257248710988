import { Redirect, Route } from 'react-router';
import { useAuth } from '@/context/AuthContext';
import setDocTitle from '@/common/setDocTitle';
import Layout from '@/layout';
import { useTranslation } from 'react-i18next';
import { usePlatform } from '@/context/PlatformContext';
import { Suspense } from 'react';
import LoadingSpinner from '../LoadingSpinner';

export default function PrivateRoute({
  title,
  renderLayout = true,
  renderSiderMenu = true,
  renderSelectMenu = true,
  renderProductsMenu = true,
  renderUserOptionsMenu = true,
  admin = false,
  global = false,
  globalBusiness = false,
  configured = true,
  providers,
  component: Component,
  componentProps = {},
  ...rest
}) {
  const { authenticated, isSuperAdmin, isPiercloudStaff } = useAuth();
  const { t } = useTranslation();
  const { context, business } = usePlatform();

  setDocTitle(t(title));

  if (admin && !isSuperAdmin() && !isPiercloudStaff()) {
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <Redirect
            to={{ pathname: '/403', state: { from: routeProps.location } }}
          />
        )}
      />
    );
  }

  if (globalBusiness && !business?.global) {
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <Redirect
            to={{ pathname: '/403', state: { from: routeProps.location } }}
          />
        )}
      />
    );
  }

  if (providers && !providers.includes(context?.provider)) {
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <Redirect
            to={{ pathname: '/404', state: { from: routeProps.location } }}
          />
        )}
      />
    );
  }

  if (!authenticated()) {
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <Redirect
            to={{ pathname: '/login', state: { from: routeProps.location } }}
          />
        )}
      />
    );
  }

  if (renderLayout) {
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <Suspense fallback={<LoadingSpinner />}>
            <Layout
              showSideMenu={renderSiderMenu}
              showSelectOptions={renderSelectMenu}
              showProductsMenu={renderProductsMenu}
              showUserOptionsMenu={renderUserOptionsMenu}
            >
              <Component {...routeProps} {...componentProps} />
            </Layout>
          </Suspense>
        )}
      />
    );
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Route
        {...rest}
        render={(routeProps) => (
          <Component {...routeProps} {...componentProps} />
        )}
      />
    </Suspense>
  );
}
