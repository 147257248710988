import {
  ApiOutlined,
  CloudSyncOutlined,
  CloudOutlined,
  LockOutlined,
  ProfileOutlined,
  ShareAltOutlined,
  TeamOutlined,
  RetweetOutlined,
  GroupOutlined,
  BankOutlined,
  DeploymentUnitOutlined,
  IdcardOutlined,
  PieChartOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import returnOnlyVisibleMenuItems from '@/common/onlyVisibleMenuItems';
import {
  pierRoles,
  orgSuperRoles,
  orgSuperAdminRole,
  pierSuperAdminRole,
  engineeringTeamRole,
} from '@/context/AuthContext';

const items = ({ t, adminRole, provider, featureFlags, business }) => {
  const allItems = [
    {
      key: 'settings/profile',
      label: t('common.profile'),
      icon: <IdcardOutlined />,
      global: true,
    },
    {
      key: 'settings/businesses',
      label: t('common.businesses'),
      icon: <ProfileOutlined />,
      global: true,
      hide: !business?.global,
      permissions: [...pierRoles, orgSuperAdminRole],
    },
    {
      key: 'settings/business-units',
      label: t('settings:business.units'),
      icon: <DeploymentUnitOutlined />,
      global: true,
      permissions: [...pierRoles, ...orgSuperRoles],
    },
    {
      key: 'settings/contexts',
      label: t('menu.sidebar.contexts'),
      icon: <ShareAltOutlined />,
      global: true,
      permissions: [...pierRoles, ...orgSuperRoles],
    },
    {
      key: 'settings/secrets',
      label: t('menu.sidebar.secrets'),
      icon: <LockOutlined />,
      global: true,
      permissions: [...pierRoles, ...orgSuperRoles],
    },
    {
      key: 'settings/billing-connectors',
      label: 'Billing Connectors',
      icon: <CloudSyncOutlined />,
      global: true,
      permissions: [...pierRoles, ...orgSuperRoles],
    },
    {
      key: 'settings/cloud-accounts',
      label: 'Cloud Accounts',
      icon: <CloudOutlined />,
      providers: ['aws', 'azure', 'gcp', 'oci'],
      permissions: [...pierRoles, ...orgSuperRoles],
    },
    {
      key: 'settings/data-connectors',
      label: 'Data Connectors',
      icon: <RetweetOutlined />,
      global: true,
      permissions: [...pierRoles, ...orgSuperRoles],
    },
    {
      key: 'settings/aws-roles',
      label: 'AWS Roles',
      icon: <LockOutlined />,
      providers: ['aws'],
      permissions: [...pierRoles, ...orgSuperRoles],
    },
    {
      key: 'settings/users',
      label: t('settings:users'),
      icon: <TeamOutlined />,
      global: true,
      permissions: [...pierRoles, ...orgSuperRoles],
    },
    {
      key: 'settings/org-users',
      label: t('settings:users.organization'),
      icon: <TeamOutlined />,
      global: true,
      hide: !business?.global,
      permissions: [...pierRoles, orgSuperAdminRole],
    },
    {
      key: 'settings/groups',
      label: t('settings:groups.access'),
      icon: <GroupOutlined />,
      global: true,
      permissions: [...pierRoles, ...orgSuperRoles],
    },
    {
      key: 'settings/orgs',
      label: t('settings:orgs'),
      icon: <BankOutlined />,
      global: true,
      permissions: [...pierRoles],
    },
    {
      key: 'settings/administrators',
      label: 'Administradores',
      icon: <TeamOutlined />,
      global: true,
      permissions: [pierSuperAdminRole],
    },
    {
      key: 'settings/clients',
      label: t('menu.sidebar.api.clients'),
      icon: <KeyOutlined />,
      global: true,
      permissions: [pierSuperAdminRole, engineeringTeamRole, ...orgSuperRoles],
    },
    {
      key: 'settings/featureflags',
      label: t('menu.sidebar.featureflags'),
      icon: <ApiOutlined />,
      global: true,
      permissions: [...pierRoles],
    },
    {
      key: 'settings/quotas',
      label: t('Quotas'),
      icon: <PieChartOutlined />,
      permissions: [...pierRoles, ...orgSuperRoles],
    },
  ];

  return returnOnlyVisibleMenuItems({ items: allItems, adminRole, provider });
};

export default items;
