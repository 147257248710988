import { forwardRef } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    width: '100%',
  },
}));

function Scrollable(
  { id = 'scrollableAnchor', children, bOffset = 0, height, padding, style },
  ref
) {
  const classes = useStyles();

  return (
    <div
      id={id}
      ref={ref}
      className={classes.root}
      style={{
        height: height ?? `calc(100vh - ${bOffset}px)`,
        padding: padding ?? 24,
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export default forwardRef(Scrollable);
