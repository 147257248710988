import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '@/components/PrivateRoute';
import { useTranslation } from 'react-i18next';

function Routes() {
  const { t } = useTranslation();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={'/lighthouse/calculated-fields/custom'}
        title={t('menu.sidebar.calculated.fields.custom')}
        component={lazy(() => import('./pages/home'))}
      />

      <PrivateRoute
        exact
        path={[
          '/lighthouse/calculated-fields/custom/new',
          '/lighthouse/calculated-fields/custom/:id',
        ]}
        title={t('menu.sidebar.calculated.fields.custom')}
        component={lazy(() => import('./pages/form'))}
      />

      <PrivateRoute
        exact
        path={'/lighthouse/calculated-fields/global'}
        title={t('menu.sidebar.calculated.fields.pier')}
        component={lazy(() => import('./pages/home'))}
      />

      <PrivateRoute
        exact
        path={[
          '/lighthouse/calculated-fields/global/new',
          '/lighthouse/calculated-fields/global/:id',
        ]}
        title={t('menu.sidebar.calculated.fields.org')}
        component={lazy(() => import('./pages/form'))}
      />

      <PrivateRoute
        exact
        path={'/lighthouse/calculated-fields/organization'}
        title={t('menu.sidebar.calculated.fields.org')}
        component={lazy(() => import('./pages/home'))}
        globalBusiness={true}
      />

      <PrivateRoute
        exact
        path={[
          '/lighthouse/calculated-fields/organization/new',
          '/lighthouse/calculated-fields/organization/:id',
        ]}
        title={t('menu.sidebar.calculated.fields.org')}
        component={lazy(() => import('./pages/form'))}
        globalBusiness={true}
      />
    </Switch>
  );
}

export default Routes;
