import { Switch, Route, Redirect } from 'react-router-dom';
import ContextRoutes from './contexts/Routes';
import SecretsRoutes from './secrets/Routes';
import GroupsRoutes from './groups/Routes';
import BillingConnectors from './billingConnectors/Routes';
import DataConnectors from './dataConnectors/Routes';
import CloudAccounts from './cloudAccounts/Routes';
import BusinessUnits from './businessUnits/Routes';
import Businesses from './businesses/Routes';
import Users from './users/Routes';
import UsersOrg from './usersOrg/Routes';
import Administrators from './administrators/Routes';
import Orgs from './orgs/Routes';
import Profile from './profile/Routes';
import FeatureFlags from './featureFlags/Routes';
import ApiClients from './apiClients/Routes';
import AwsRoles from './awsRoles/Routes';
import Quotas from './quotas/Routes';

function Routes() {
  return (
    <Switch>
      <Route path="/settings/contexts*">
        <ContextRoutes />
      </Route>

      <Route path="/settings/secrets*">
        <SecretsRoutes />
      </Route>

      <Route path="/settings/groups*">
        <GroupsRoutes />
      </Route>

      <Route path="/settings/billing-connectors*">
        <BillingConnectors />
      </Route>

      <Route path="/settings/data-connectors*">
        <DataConnectors />
      </Route>

      <Route path="/settings/cloud-accounts*">
        <CloudAccounts />
      </Route>

      <Route path="/settings/orgs*">
        <Orgs />
      </Route>

      <Route path="/settings/business-units*">
        <BusinessUnits />
      </Route>

      <Route path="/settings/businesses*">
        <Businesses />
      </Route>

      <Route path="/settings/users*">
        <Users />
      </Route>

      <Route path="/settings/org-users*">
        <UsersOrg />
      </Route>

      <Route path="/settings/administrators*">
        <Administrators />
      </Route>

      <Route path="/settings/profile*">
        <Profile />
      </Route>

      <Route path="/settings/featureflags*">
        <FeatureFlags />
      </Route>

      <Route path="/settings/clients*">
        <ApiClients />
      </Route>

      <Route path="/settings/aws-roles*">
        <AwsRoles />
      </Route>

      <Route path="/settings/quotas*">
        <Quotas />
      </Route>

      <Redirect from="/settings" to="/settings/profile" />
    </Switch>
  );
}

export default Routes;
