export const auth = {
  EVENT_LOGIN: 'LOGIN',
};

export const workspaces = {
  EVENT_WORKSPACE_VIEW_SUCCESS: 'WORKSPACE_VIEW_SUCCESS',
  EVENT_WORKSPACE_VIEW_ERROR: 'WORKSPACE_VIEW_ERROR',
  EVENT_LH_WS_NEW_VIEW: 'LH_WS_NEW_VIEW',
};

export const organizationalMap = {
  EVENT_ORGANIZATIONAL_MAP_CREATED: 'ORGANIZATIONAL_MAP_CREATED',
  EVENT_ORGANIZATIONAL_MAP_UPDATED: 'ORGANIZATIONAL_MAP_UPDATED',
  EVENT_ORGANIZATIONAL_MAP_DELETED: 'ORGANIZATIONAL_MAP_DELETED',
};
